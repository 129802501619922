<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Confirm"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :confirm="true"
    :isLoading="isLoading"
    width="600px"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 v-if="userRole === 'sponsors'" class="font-bold mb-0">
            Delete pre-purchased user
          </h2>
          <h2 v-else class="font-bold mb-0">Delete direct pay user</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <div class="content mt-4">
        <h1>
          Are you sure you want to delete the user
          <span class="font-bold">{{ userEmail }}</span
          >?
        </h1>
        <div class="my-4 p-4 bg-gray-100 rounded-lg flex items-center">
          <i
            class="fa-solid fa-exclamation-triangle text-yellow-500 text-2xl mr-3"
          ></i>
          <p v-if="userRole !== 'sponsors'">
            Deleting this user will update their subscription to the standard
            subscription plan starting from the next billing cycle.
            Additionally, the user and their players will be unlinked from this
            location.
          </p>
          <p v-else>
            Deleting this user will unlink them and their players from this
            location, deactivate them, and free up the corresponding seats.
          </p>
        </div>
        <div>
          <input
            type="checkbox"
            v-model="isConfirmChecked"
            @change="toggleConfirmButton"
          />
          <span class="ml-1">
            I understand, and I want to delete this user.
          </span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";

const props = defineProps({
  show: Boolean,
  isLoading: Boolean,
  userRole: String,
  userEmail: String,
});

const emit = defineEmits(["update:show", "confirm"]);

const isConfirmDisabled = ref(true);
const isConfirmChecked = ref(false);

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;
  emit("update:show", false);
}

function toggleConfirmButton() {
  isConfirmDisabled.value = !isConfirmChecked.value;
}

function clearStates() {
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;
}

watch(
  () => props.show,
  (newValue) => {
    clearStates();
  }
);

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
