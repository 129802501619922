<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled || !seatsAvailable || isLoadingData"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Confirm"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :confirm="true"
    :isLoading="isLoading"
    width="600px"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Update subscription</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <div class="content mt-4">
        <p class="mt-2">
          Number of free seats:
          <span class="font-bold">{{ seatsAvailable }}</span>
        </p>
        <p class="mt-2">
          Select the number of seats to remove from your subscription. If the
          number of seats equals the total purchased seats, the subscription
          will be canceled.
        </p>
        <div class="mt-4">
          <h1 class="font-bold">Number of seats to remove:</h1>
          <div class="mt-4 flex items-center">
            <div class="flex">
              <p
                class="border py-0.5 px-1 cursor-pointer"
                @click="decreaseSeats"
                :disabled="seatsToRemove <= 1"
              >
                <em class="fa-solid fa-minus"></em>
              </p>
              <input
                class="w-1/2 text-center border p-0.5 cursor-pointer"
                type="number"
                v-model="seatsToRemove"
                :min="1"
                :max="seatsAvailable"
                @input="handleSeatsChange"
              />
              <p
                class="border py-0.5 px-1 cursor-pointer"
                @click="increaseSeats"
                :disabled="seatsToRemove >= seatsAvailable"
              >
                <em class="fa-solid fa-plus"></em>
              </p>
            </div>
          </div>
        </div>
        <div class="my-4 p-4 bg-gray-100 rounded-lg flex items-center">
          <img src="@/assets/icons/Info.svg" alt="Info" class="h-6 w-6 mr-1" />
          <p class="text-gray-400 text-sm">
            The new amount to be paid on the next invoice will be
            <em v-if="isLoadingData" class="fa fa-spinner fa-spin"></em>
            <span v-else class="font-bold">${{ upcomingTotalInvoce }}</span>
          </p>
        </div>
        <div>
          <input
            type="checkbox"
            v-model="isConfirmChecked"
            @change="toggleConfirmButton"
          />
          <span class="ml-1">
            I understand, and I want to update the subscription.
          </span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";
import { subscriptionNextInvoiceTotalBeforeQuantityChange } from "@/services/stripe/stripe.js";

const props = defineProps({
  show: Boolean,
  isLoading: Boolean,
  userRole: String,
  userEmail: String,
  seatsAvailable: Number,
});

const emit = defineEmits(["update:show", "confirm"]);

const isConfirmDisabled = ref(true);
const isConfirmChecked = ref(false);
const isLoadingData = ref(false);
const upcomingTotalInvoce = ref(0);
const seatsToRemove = ref(1);

async function fetchNextInvoiceTotal() {
  isLoadingData.value = true;
  try {
    const nextInvoiceTotal =
      await subscriptionNextInvoiceTotalBeforeQuantityChange(
        "decrease",
        "none",
        seatsToRemove.value,
        true
      );
    upcomingTotalInvoce.value = nextInvoiceTotal.next_invoice_total;
  } catch (error) {
    isConfirmDisabled.value = true;
  } finally {
    isLoadingData.value = false;
  }
}

function handleConfirm() {
  emit("confirm", seatsToRemove.value);
}

function handleCancel() {
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;

  emit("update:show", false);
}

function decreaseSeats() {
  if (seatsToRemove.value > 1) {
    seatsToRemove.value--;
    fetchNextInvoiceTotal();
  }
}

function increaseSeats() {
  if (seatsToRemove.value < props.seatsAvailable) {
    seatsToRemove.value++;
    fetchNextInvoiceTotal();
  }
}

function handleSeatsChange() {
  if (seatsToRemove.value < 1) {
    seatsToRemove.value = 1;
  } else if (seatsToRemove.value > props.seatsAvailable) {
    seatsToRemove.value = props.seatsAvailable;
  }
  fetchNextInvoiceTotal();
}

function toggleConfirmButton() {
  isConfirmDisabled.value = !isConfirmChecked.value;
}

function clearStates() {
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;
  upcomingTotalInvoce.value = 0;
  seatsToRemove.value = 1;
  isLoadingData.value = false;
}

watch(
  () => props.show,
  (newValue) => {
    clearStates();
    if (newValue) {
      fetchNextInvoiceTotal();
    }
  }
);

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
