<template>
  <div>
    <div v-if="data && data.results.length > 0">
      <div class="overflow-scroll md:overflow-auto">
        <table class="w-full m-auto table-auto">
          <thead>
            <tr>
              <th class="text-center" v-if="checkboxes">Select</th>
              <th v-for="label in labels" :key="label" class="py-3 text-center">
                {{ label }}
              </th>
            </tr>
          </thead>
          <tbody class="h-4/6">
            <tr
              v-for="user in data.results.filter((item) =>
                getRoles(userType).includes(item.role)
              )"
              :key="user.id"
              class="bg-white"
            >
              <td v-if="checkboxes" class="text-center">
                <input
                  type="checkbox"
                  v-model="selectedItems"
                  :value="user.email"
                />
              </td>
              <td class="text-center" v-if="userType !== 'pending'">
                {{ user.first_name }} {{ user.last_name }}
              </td>
              <td class="text-center">
                {{ user.email }}
              </td>
              <td
                v-if="userType === 'admins' || userType === 'pending'"
                class="text-center"
              >
                {{ user.role }}
              </td>
              <td v-if="userType !== 'admins'" class="text-center">
                {{ user.profiles }}
              </td>
              <td class="text-center">{{ formatDate(user.create_at) }}</td>
              <td
                v-if="userType !== 'admins'"
                @click="handleDelete(user.id, user.email)"
                class="text-center"
              >
                <i class="fa-regular fa-trash-can cursor-pointer"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-white text-center p-3 w-full">
        <span
          @click="previousPage"
          class="mr-3 font-bold cursor-pointer"
          v-if="actualPage > 1"
          >&lt;</span
        >
        <p class="inline">{{ actualPage }} of {{ totalPages }} pages</p>
        <span
          @click="nextPage"
          class="ml-3 font-bold cursor-pointer"
          v-if="data && data.next"
          >&gt;</span
        >
      </div>
    </div>
    <div v-else>
      <div class="overflow-scroll md:overflow-auto">
        <table class="w-full m-auto">
          <thead>
            <tr>
              <th v-for="label in labels" :key="label" class="py-3 text-center">
                {{ label }}
              </th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <div class="bg-white w-full m-auto p-16">
        <img src="@/assets/empty-table.svg" alt="" class="block m-auto" />
      </div>
    </div>
  </div>
  <DeleteLocationUserInvitationModal
    :show="showDeleteInvitationModal"
    :isLoading="isDeleletingInvitation"
    @cancel="handleCancel"
    @update:show="showDeleteInvitationModal = $event"
    :userEmail="selectedUserEmail"
    @confirm="removeInvitation"
  />
  <DeleteLocationUserModal
    :show="showDeleteUserModal"
    :isLoading="isRemovingUser"
    @cancel="handleCancel"
    @confirm="removeUser"
    @update:show="showDeleteUserModal = $event"
    :userRole="userType"
    :userEmail="selectedUserEmail"
  />
</template>

<script setup>
import {
  ref,
  watch,
  computed,
} from "vue";
import { useToast } from "vue-toastification";

import { deleteInvitation } from "@/services/location/location.js";
import { removeLocationUser } from "@/services/stripe/stripe.js";
import DeleteLocationUserModal from "@/components/location/DeleteLocationUserModal.vue";
import DeleteLocationUserInvitationModal from "@/components/location/DeleteLocationUserInvitationModal.vue";

const toast = useToast();

const selectedItems = ref([]);
const actualPage = ref(1);
const showDeleteUserModal = ref(false);
const showDeleteInvitationModal = ref(false);
const selectedUserEmail = ref("");
const selectedUserId = ref("");
const isDeleletingInvitation = ref(false);
const isRemovingUser = ref(false);
const totalPages = computed(() =>
  props?.data?.count ? Math.ceil(props.data.count / 10) : 1
);

const emit = defineEmits(["changePage", "updateUsersData"]);

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  userType: {
    type: String,
    default: "admins",
  },
  labels: {
    type: Array,
    default: null,
  },
  edit: {
    type: Boolean,
    default: false,
  },
  checkboxes: {
    type: Boolean,
    default: false,
  },
  activeTab: {
    type: String,
    default: "Location Admins",
  },
});

function getRoles(userType) {
  const roles = {
    admins: ["Location_admin", "Location_delegate"],
    sponsors: ["Sponsor_account"],
    location_users: ["Location_user"],
    pending: [
      "Location_admin",
      "Location_delegate",
      "Sponsor_account",
      "Location_user",
    ],
  };

  return roles[userType];
}

async function handleDelete(id, eamil) {
  if (props.userType === "pending") {
    showDeleteInvitationModal.value = true;
  } else {
    showDeleteUserModal.value = true;
  }
  selectedUserId.value = id;
  selectedUserEmail.value = eamil;
}

async function removeInvitation() {
  isDeleletingInvitation.value = true;
  await deleteInvitation(selectedUserId.value);
  emit("updateUsersData");
  showDeleteInvitationModal.value = false;
  isDeleletingInvitation.value = false;
  actualPage.value = 1;
}

async function removeUser() {
  isRemovingUser.value = true;
  try {
    const sponsoredUser = props.userType === "sponsors" ? true : false;
    await removeLocationUser(selectedUserId.value, sponsoredUser);
    handleMessage("The user was successfully removed", false);
    emit("updateUsersData");
    actualPage.value = 1;
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    showDeleteUserModal.value = false;
    isRemovingUser.value = false;
  }
}

function handleCancel() {
  showDeleteUserModal.value = false;
  showDeleteInvitationModal.value = false;
}

function formatDate(date) {
  const dateAdded = new Date(date);
  return `${
    dateAdded.getMonth() + 1
  }/${dateAdded.getDate()}/${dateAdded.getFullYear()}`;
}

function nextPage() {
  if (props?.data.next) {
    actualPage.value++;
    emit("changePage", actualPage.value);
  }
}

function previousPage() {
  if (props?.data.previous && actualPage.value > 1) {
    actualPage.value--;
    emit("changePage", actualPage.value);
  }
}

function handleMessage(message, error) {
  error ? toast.error(message) : toast.success(message);
}

defineExpose({
  selectedItems,
});

watch(
  () => props.activeTab,
  (newActiveTab, oldActiveTab) => {
    if (newActiveTab !== oldActiveTab) {
      actualPage.value = 1;
    }
  }
);
</script>

<style>
table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}
</style>
