<template>
  <div class="h-screen">
    <div class="flex justify-between items-center p-5 mt-2">
      <h1 class="font-semibold text-xl text-white">Manage Users</h1>
      <div class="text-center" v-if="activeTab !== 'Pending Users'">
        <button
          v-if="
            activeTab === 'Pre-Purchased Users' &&
            user.role === 'Location_admin'
          "
          id="b-pay"
          class="text-color font-semibold bg-mindaro p-2 px-4 mr-2"
          type="submit"
          @click="showSeatCheckoutModal = true"
        >
          Pre-Purchase User Profiles
        </button>
        <button
          id="b-pay"
          form="stripe"
          class="text-color font-semibold bg-mindaro p-2 px-4"
          type="submit"
          @click="handleAddUserButtonClick"
          :disabled="userRole === 'Sponsor_account' && !seatsAvailable"
        >
          {{ buttonText }}
        </button>
        <button
          v-if="
            activeTab === 'Pre-Purchased Users' &&
            user.role === 'Location_admin'
          "
          id="b-pay"
          class="text-color font-semibold bg-mindaro p-2 px-4 ml-2"
          type="submit"
          @click="showCancelSubscriptionModal = true"
          :disabled="!seatsAvailable"
        >
          Update Subscription
        </button>
      </div>
    </div>
    <TabsWrapper class="p-5" @tab-changed="tabChanged">
      <Tabs title="Location Admins">
        <LocationsTable
          :data="locationUsers"
          :labels="['Name', 'Email', 'Role', 'Created at']"
          userType="admins"
          :activeTab="activeTab"
          @changePage="loadLocationUsers"
      /></Tabs>
      <Tabs title="Pre-Purchased Users">
        <LocationsTable
          :data="locationUsers"
          :labels="['Name', 'Email', 'Profiles', 'Created at', '']"
          userType="sponsors"
          :activeTab="activeTab"
          @changePage="loadLocationUsers"
          @updateUsersData="getUsersData"
      /></Tabs>
      <Tabs title="Direct Pay Users">
        <LocationsTable
          :data="locationUsers"
          :labels="['Name', 'Email', 'Profiles', 'Created at', '']"
          userType="location_users"
          :activeTab="activeTab"
          @changePage="loadLocationUsers"
          @updateUsersData="getUsersData"
      /></Tabs>
      <Tabs title="Pending Users">
        <LocationsTable
          :data="invitations"
          :labels="['Email', 'role', 'Profiles', 'Created at', '']"
          userType="pending"
          :activeTab="activeTab"
          @changePage="requestInvitations"
          @updateUsersData="getUsersData"
      /></Tabs>
    </TabsWrapper>
  </div>
  <RegisterLocationUserModal
    :show="showAddUserModal"
    @update:show="showAddUserModal = $event"
    @confirm="sendUserInvitation"
    :title="modalTittle"
    :userRole="userRole"
    :isLoading="isSendingInvitation"
  />
  <SeatCheckoutModal
    :show="showSeatCheckoutModal"
    @update:show="showSeatCheckoutModal = $event"
    @confirm="handleBuySeatsConfirm"
  />
  <CancelLocationSubscriptionModal
    :show="showCancelSubscriptionModal"
    @update:show="showCancelSubscriptionModal = $event"
    @confirm="handleCancelLocationSubscription"
    :seatsAvailable="totalSeats"
    :isLoading="isCancelingSubscription"
  />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import LocationsTable from "@/components/location/LocationAdminTable.vue";
import Tabs from "@/components/general/Tabs.vue";
import TabsWrapper from "@/components/general/TabsWrapper.vue";
import {
  getLocationUsers,
  getInvitations,
  registrationEmail,
  createLocationCode,
  numberSeats,
} from "@/services/location/location.js";
import { cancelLocationSubscription } from "@/services/stripe/stripe.js";
import SeatCheckoutModal from "@/components/checkout/modals/SeatCheckoutModal.vue";
import RegisterLocationUserModal from "@/components/location/RegisterLocationUserModal.vue";
import CancelLocationSubscriptionModal from "@/components/location/CancelLocationSubscriptionModal.vue";

const store = useStore();
const toast = useToast();

const locationUsers = ref(null);
const invitations = ref(null);
const showAddUserModal = ref(false);
const showSeatCheckoutModal = ref(false);
const showCancelSubscriptionModal = ref(false);
const userRole = ref("Location_delegate");
const activeTab = ref("Location Admins");
const buttonText = ref("Add delegate user");
const modalTittle = ref("Add delegate user");
const totalSeats = ref(0);
const seatsAvailable = ref(false);
const isSendingInvitation = ref(false);
const isCancelingSubscription = ref(false);

const user = computed(() => store.getters["user/getUserData"]);

onMounted(async () => {
  await getUsersData();
});

async function getUsersData() {
  await loadLocationUsers();
  await requestInvitations();
  await getNumberSeats();
}

async function loadLocationUsers(page) {
  let roles = [];
  if (activeTab.value === "Pre-Purchased Users") {
    roles = ["Sponsor_account"];
  } else if (activeTab.value === "Direct Pay Users") {
    roles = ["Location_user"];
  }
  const response = await getLocationUsers(page, roles);
  locationUsers.value = response.data;
}

async function requestInvitations(page) {
  const invitationsResponse = await getInvitations(page);
  invitations.value = invitationsResponse.data;
}

async function getNumberSeats() {
  const response = await numberSeats({ location: user.value.location });
  if (response.success) {
    totalSeats.value = response.data.number_seats;
    seatsAvailable.value = response.data.number_seats > 0;
  }
}

async function sendUserInvitation(data) {
  if (userRole.value === "Sponsor_account") {
    if (data.profiles > totalSeats.value) {
      handleMessage(
        "There are no seats available; you currently have " +
          totalSeats.value +
          " seats",
        true
      );
      return;
    }
  }
  isSendingInvitation.value = true;

  const locationCode = await getLocationCode();
  if (locationCode) {
    sendRegistrationEmail(data, locationCode);
  }
}

async function getLocationCode() {
  const codeResponse = await createLocationCode(
    user.value.location,
    userRole.value
  );
  
  if (!codeResponse.success) {
    isSendingInvitation.value = false;
    handleMessage("Error getting location code", true);
    return;
  }
  return codeResponse.data.location_code;
}

async function sendRegistrationEmail(data, code) {
  try {
    await registrationEmail({
      email: data.email,
      code,
      role: userRole.value,
      dependents: userRole.value === "Sponsor_account" ? data.profiles : 0,
    });

    handleMessage("The invitation was successfully sent!", false);
    await getUsersData();
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    showAddUserModal.value = false;
    isSendingInvitation.value = false;
  }
}

async function tabChanged(newTabTitle) {
  activeTab.value = newTabTitle;
  switch (newTabTitle) {
    case "Location Admins":
      buttonText.value = "Add delegate user";
      userRole.value = "Location_delegate";
      await loadLocationUsers();
      break;
    case "Pre-Purchased Users":
      buttonText.value = "Add User - Pre-Purchased Profile";
      userRole.value = "Sponsor_account";
      await loadLocationUsers();
      break;
    case "Direct Pay Users":
      buttonText.value = "Add User – User Direct Pay";
      userRole.value = "Location_user";
      await loadLocationUsers();
      break;
    case "Pending Users":
      await requestInvitations();
      break;
    default:
      buttonText.value = "Add delegate user";
      userRole.value = "Location_delegate";
  }
}

function handleAddUserButtonClick() {
  if (activeTab.value === "Location Admins") {
    showAddUserModal.value = true;
    modalTittle.value = "Add delegate user";
  } else if (activeTab.value === "Pre-Purchased Users") {
    showAddUserModal.value = true;
    modalTittle.value = "Add User - Pre-Purchased Profile";
  } else if (activeTab.value === "Direct Pay Users") {
    showAddUserModal.value = true;
    modalTittle.value = "Add User – User Direct Pay";
  }
}

async function handleCancelLocationSubscription(seats) {
  try {
    isCancelingSubscription.value = true;
    await cancelLocationSubscription(seats);
    handleMessage("The subscription was successfully updated", false);
    await getUsersData();
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    showCancelSubscriptionModal.value = false;
    isCancelingSubscription.value = false;
  }
}

async function handleBuySeatsConfirm() {
  await getUsersData();
  showSeatCheckoutModal.value = false;
}

function handleMessage(message, error) {
  error ? toast.error(message) : toast.success(message);
}
</script>

<style lang="scss">
#b-pay:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
