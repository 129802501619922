<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Send invitation"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :confirm="true"
    :isLoading="isLoading"
    width="600px"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">{{ title }}</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <div class="content mt-5">
        <div class="form__div mt-4">
          <input
            type="email"
            class="form__input"
            placeholder=" "
            v-model="email"
            required
          />
          <label class="form__label" for="email">Email</label>
        </div>
      </div>
      <h6 v-if="emailError" class="text-red-600 mb-4">
        {{ emailError }}
      </h6>
      <div v-if="userRole === 'Sponsor_account'">
        <div class="form__div mt-2">
          <input
            class="form__input"
            type="number"
            placeholder=" "
            v-model="profiles"
            required
          />
          <label for="" class="form__label"
            >Number of seats assigned to the user</label
          >
        </div>
        <div class="">
          <div>
            <input
              class="ml-1"
              type="checkbox"
              v-model="isConfirmChecked"
              @change="toggleConfirmButton"
            />
            <span class="ml-1 italic">
              Confirm the user is 18+ or that you have consent from the parent
              according to our
              <a
                class="underline font-bold"
                target="_blank"
                href="https://www.mastermindsports.com/terms-of-service"
                >terms of service.</a
              >
            </span>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";

const props = defineProps({
  show: Boolean,
  isLoading: Boolean,
  userRole: String,
  title: String,
});

const emit = defineEmits(["update:show", "confirm"]);

const email = ref(null);
const emailError = ref(null);
const profiles = ref(1);
const isConfirmChecked = ref(false);
const isConfirmDisabled = computed(() => {
  if (props.userRole !== "Sponsor_account") {
    return !email.value || props.isLoading;
  }

  return (
    !isConfirmChecked.value ||
    profiles.value <= 0 ||
    !email.value ||
    props.isLoading
  );
});

function isValidEmail(email) {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
}

function handleConfirm() {
  if (!isValidEmail(email.value)) {
    emailError.value = "Invalid email";
    return;
  }

  emit("confirm", { email: email.value, profiles: profiles.value });
}

function handleCancel() {
  emit("update:show", false);
}

function clearStates() {
  email.value = null;
  emailError.value = null;
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;
  profiles.value = 1;
}

watch(email, (newValue) => {
  emailError.value = null;
});

watch(
  () => props.show,
  (newValue) => {
    clearStates();
  }
);
</script>
