<template>
  <Modal
    :isConfirmDisabled="true"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Confirm"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="false"
    :confirm="false"
    :isLoading="isLoading"
    width="1000px"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Buy Seats</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <SeatCheckoutForm @confirm="handleConfirm" />
    </template>
  </Modal>
</template>

<script setup>
import Modal from "@/components/general/ConfirmationModal.vue";
import SeatCheckoutForm from "@/components/checkout/SeatCheckoutForm.vue";

const props = defineProps({
  show: Boolean,
  isLoading: Boolean,
});

const emit = defineEmits(["update:show", "confirm"]);

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  emit("update:show", false);
}
</script>
