<template>
  <Modal
    :show="show"
    :cancel="true"
    cancelText="Cancel"
    confirmationText="Confirm"
    :isConfirmDisabled="isConfirmDisabled"
    width="450px"
    @cancel="handleCancel"
    @confirm="handleConfirm"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Delete user invitation</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <h1 class="mt-4 mb-8">
        Are you sure you want to delete the invitation to
        <span class="font-bold">{{ userEmail }}</span
        >?
      </h1>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";

const props = defineProps({
  show: Boolean,
  isLoading: Boolean,
  userEmail: String,
});

const isConfirmDisabled = ref(false);

const emit = defineEmits(["update:show", "confirm"]);

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  emit("update:show", false);
}

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
